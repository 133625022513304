import React from "react";
import { TypeButtonProps } from "./ButtonTypes";
import Container from "./styles";

const Button = ({
  href,
  appearance = "unstyled",
  active = false,
  disabled,
  external,
  children,
  size = "default",
  innerRef,
  onClick,
  title,
  qa = {},
  as,
  ariaLabel,
  ...rest
}: TypeButtonProps) => {
  if (!href && external) {
    // eslint-disable-next-line no-console
    console.warn(
      "Warning: external prop cannot be set without a href declaration"
    );
  }

  return (
    <Container
      title={title}
      active={active}
      href={href}
      target={external ? "_blank" : undefined}
      rel={external ? "noopener noreferrer" : undefined}
      as={as || (href ? "a" : "button")}
      type={href ? undefined : "button"}
      aria-disabled={disabled ? disabled : undefined}
      disabled={disabled}
      buttonSize={size}
      appearance={appearance || "unstyled"}
      ref={innerRef}
      onClick={onClick}
      data-qa-button={title || ""}
      data-qa-button-isdisabled={disabled === true}
      aria-label={ariaLabel}
      {...qa}
      {...rest}
    >
      {children}
    </Container>
  );
};

Button.displayName = "Button";
export default Button;
