import {
  ResponsiveValue,
  compose,
  system,
  variant,
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from "styled-system";
import { TypeResponsiveTypographySize } from "./types/system-props";
import { Property as CssProperty } from "csstype";

// https://styled-system.com/custom-props
export interface TypeCustomSystemProps {
  cursor?: ResponsiveValue<CssProperty.Cursor>;
  whiteSpace?: ResponsiveValue<CssProperty.WhiteSpace>;
}

export const customSystemProps = compose(
  system({
    cursor: true,
    whiteSpace: true,
  })
);

// https://styled-system.com/variants
export interface TypeVariantSystemProps {
  typeScale?: TypeResponsiveTypographySize;
}

export const variantSystemProps = compose(
  variant({
    key: "typography",
    prop: "typeScale",
  })
);

export interface TypeSystemProps
  extends TypeCustomSystemProps,
    TypeVariantSystemProps,
    BackgroundProps,
    BorderProps,
    ColorProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    TypographyProps {}

export const systemProps = compose(
  customSystemProps,
  variantSystemProps,

  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography
);

// including deprecated support for old type names in case they are needed for the intial TS release
export {
  type BackgroundProps as TypeBackgroundSystemProps,
  background as backgroundSystemProps,
  type BorderProps as TypeBorderSystemProps,
  border as borderSystemProps,
  /**
    @deprecated: Use TypeColorSystemProps instead.
  */
  type ColorProps as TypeBackgroundColorSystemProp,
  type ColorProps as TypeColorSystemProps,
  color as colorSystemProps,
  type FlexboxProps as TypeFlexboxSystemProps,
  flexbox as flexboxSystemProps,
  type GridProps as TypeGridSystemProps,
  grid as gridSystemProps,
  type LayoutProps as TypeLayoutSystemProps,
  layout as layoutSystemProps,
  type PositionProps as TypePositionSystemProps,
  position as positionSystemProps,
  type ShadowProps as TypeShadowSystemProps,
  shadow as shadowSystemProps,
  type SpaceProps as TypeSpaceSystemProps,
  space as spaceSystemProps,
  type TypographyProps as TypeTypographySystemProps,
  typography as typographySystemProps,
};
