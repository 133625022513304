  const NETWORK_COLOR_TWITTER = '#000000';
  const NETWORK_COLOR_TWITTER_LIKE = '#e0245e';
  const NETWORK_COLOR_X = '#000000';
  const NETWORK_COLOR_X_LIKE = '#e0245e';
  const NETWORK_COLOR_FACEBOOK = '#1877F2';
  const NETWORK_COLOR_FACEBOOK_AUDIENCE_NETWORK = '#58409B';
  const NETWORK_COLOR_PAYPAL_GOLD = '#FFD140';
  const NETWORK_COLOR_LINKEDIN = '#0A66C2';
  const NETWORK_COLOR_INSTAGRAM = '#e4405f';
  const NETWORK_COLOR_FEEDLY = '#2bb24c';
  const NETWORK_COLOR_ANALYTICS = '#ef6c00';
  const NETWORK_COLOR_YOUTUBE = '#ff0000';
  const NETWORK_COLOR_MESSENGER = '#000000';
  const NETWORK_COLOR_SNAPCHAT = '#fffc00';
  const NETWORK_COLOR_PINTEREST = '#E60023';
  const NETWORK_COLOR_REDDIT = '#ff4500';
  const NETWORK_COLOR_TUMBLR = '#35465c';
  const NETWORK_COLOR_GOOGLE_MY_BUSINESS = '#4285F4';
  const NETWORK_COLOR_TRIPADVISOR = '#00B087';
  const NETWORK_COLOR_GLASSDOOR = '#0CAA41';
  const NETWORK_COLOR_APPLE_APP_STORE = '#17C8F5';
  const NETWORK_COLOR_GOOGLE_PLAY_STORE = '#01875F';
  const NETWORK_COLOR_GOOGLE_BUSINESS_MESSAGES = '#1A73EA';
  const NETWORK_COLOR_SALESFORCE = '#1589EE';
  const NETWORK_COLOR_ZENDESK = '#03363D';
  const NETWORK_COLOR_HUBSPOT = '#FF7A59';
  const NETWORK_COLOR_MICROSOFT_DYNAMICS = '#002050';
  const NETWORK_COLOR_SHOPIFY = '#96bf48';
  const NETWORK_COLOR_YELP = '#FF1A1A';
  const NETWORK_COLOR_WHATSAPP = '#59CE72';
  const NETWORK_COLOR_TIKTOK = '#000000';
  const NETWORK_COLOR_THREADS = '#000000';
  const NETWORK_COLOR_TRUSTPILOT = '#04DA8D';
  const NETWORK_COLOR_TRUSTPILOT_0_STAR = '#DCDCE6';
  const NETWORK_COLOR_TRUSTPILOT_1_STAR = '#FF3722';
  const NETWORK_COLOR_TRUSTPILOT_2_STAR = '#FF8622';
  const NETWORK_COLOR_TRUSTPILOT_3_STAR = '#FFCE00';
  const NETWORK_COLOR_TRUSTPILOT_4_STAR = '#73CF11';
  const NETWORK_COLOR_TRUSTPILOT_5_STAR = '#00B67A';

export {
  NETWORK_COLOR_TWITTER,
  NETWORK_COLOR_TWITTER_LIKE,
  NETWORK_COLOR_X,
  NETWORK_COLOR_X_LIKE,
  NETWORK_COLOR_FACEBOOK,
  NETWORK_COLOR_FACEBOOK_AUDIENCE_NETWORK,
  NETWORK_COLOR_PAYPAL_GOLD,
  NETWORK_COLOR_LINKEDIN,
  NETWORK_COLOR_INSTAGRAM,
  NETWORK_COLOR_FEEDLY,
  NETWORK_COLOR_ANALYTICS,
  NETWORK_COLOR_YOUTUBE,
  NETWORK_COLOR_MESSENGER,
  NETWORK_COLOR_SNAPCHAT,
  NETWORK_COLOR_PINTEREST,
  NETWORK_COLOR_REDDIT,
  NETWORK_COLOR_TUMBLR,
  NETWORK_COLOR_GOOGLE_MY_BUSINESS,
  NETWORK_COLOR_TRIPADVISOR,
  NETWORK_COLOR_GLASSDOOR,
  NETWORK_COLOR_APPLE_APP_STORE,
  NETWORK_COLOR_GOOGLE_PLAY_STORE,
  NETWORK_COLOR_GOOGLE_BUSINESS_MESSAGES,
  NETWORK_COLOR_SALESFORCE,
  NETWORK_COLOR_ZENDESK,
  NETWORK_COLOR_HUBSPOT,
  NETWORK_COLOR_MICROSOFT_DYNAMICS,
  NETWORK_COLOR_SHOPIFY,
  NETWORK_COLOR_YELP,
  NETWORK_COLOR_WHATSAPP,
  NETWORK_COLOR_TIKTOK,
  NETWORK_COLOR_THREADS,
  NETWORK_COLOR_TRUSTPILOT,
  NETWORK_COLOR_TRUSTPILOT_0_STAR,
  NETWORK_COLOR_TRUSTPILOT_1_STAR,
  NETWORK_COLOR_TRUSTPILOT_2_STAR,
  NETWORK_COLOR_TRUSTPILOT_3_STAR,
  NETWORK_COLOR_TRUSTPILOT_4_STAR,
  NETWORK_COLOR_TRUSTPILOT_5_STAR
};

export default {
  NETWORK_COLOR_TWITTER,
  NETWORK_COLOR_TWITTER_LIKE,
  NETWORK_COLOR_X,
  NETWORK_COLOR_X_LIKE,
  NETWORK_COLOR_FACEBOOK,
  NETWORK_COLOR_FACEBOOK_AUDIENCE_NETWORK,
  NETWORK_COLOR_PAYPAL_GOLD,
  NETWORK_COLOR_LINKEDIN,
  NETWORK_COLOR_INSTAGRAM,
  NETWORK_COLOR_FEEDLY,
  NETWORK_COLOR_ANALYTICS,
  NETWORK_COLOR_YOUTUBE,
  NETWORK_COLOR_MESSENGER,
  NETWORK_COLOR_SNAPCHAT,
  NETWORK_COLOR_PINTEREST,
  NETWORK_COLOR_REDDIT,
  NETWORK_COLOR_TUMBLR,
  NETWORK_COLOR_GOOGLE_MY_BUSINESS,
  NETWORK_COLOR_TRIPADVISOR,
  NETWORK_COLOR_GLASSDOOR,
  NETWORK_COLOR_APPLE_APP_STORE,
  NETWORK_COLOR_GOOGLE_PLAY_STORE,
  NETWORK_COLOR_GOOGLE_BUSINESS_MESSAGES,
  NETWORK_COLOR_SALESFORCE,
  NETWORK_COLOR_ZENDESK,
  NETWORK_COLOR_HUBSPOT,
  NETWORK_COLOR_MICROSOFT_DYNAMICS,
  NETWORK_COLOR_SHOPIFY,
  NETWORK_COLOR_YELP,
  NETWORK_COLOR_WHATSAPP,
  NETWORK_COLOR_TIKTOK,
  NETWORK_COLOR_THREADS,
  NETWORK_COLOR_TRUSTPILOT,
  NETWORK_COLOR_TRUSTPILOT_0_STAR,
  NETWORK_COLOR_TRUSTPILOT_1_STAR,
  NETWORK_COLOR_TRUSTPILOT_2_STAR,
  NETWORK_COLOR_TRUSTPILOT_3_STAR,
  NETWORK_COLOR_TRUSTPILOT_4_STAR,
  NETWORK_COLOR_TRUSTPILOT_5_STAR
};