  const COLOR_GREEN_0 = '#ebf9eb';
  const COLOR_GREEN_100 = '#d7f4d7';
  const COLOR_GREEN_200 = '#c2f2bd';
  const COLOR_GREEN_300 = '#98e58e';
  const COLOR_GREEN_400 = '#75dd66';
  const COLOR_GREEN_500 = '#59cb59';
  const COLOR_GREEN_600 = '#2bb656';
  const COLOR_GREEN_700 = '#0ca750';
  const COLOR_GREEN_800 = '#008b46';
  const COLOR_GREEN_900 = '#006b40';
  const COLOR_GREEN_1000 = '#08422f';
  const COLOR_GREEN_1100 = '#002b20';
  const COLOR_TEAL_0 = '#e5f9f5';
  const COLOR_TEAL_50 = '#CCF1EA';
  const COLOR_TEAL_100 = '#cdf7ef';
  const COLOR_TEAL_200 = '#b3f2e6';
  const COLOR_TEAL_300 = '#7dead5';
  const COLOR_TEAL_400 = '#24e0c5';
  const COLOR_TEAL_500 = '#08c4b2';
  const COLOR_TEAL_600 = '#00a99c';
  const COLOR_TEAL_700 = '#0b968f';
  const COLOR_TEAL_800 = '#067c7c';
  const COLOR_TEAL_900 = '#026661';
  const COLOR_TEAL_1000 = '#083f3f';
  const COLOR_TEAL_1100 = '#002528';
  const COLOR_AQUA_0 = '#d9fcfb';
  const COLOR_AQUA_100 = '#c5f9f9';
  const COLOR_AQUA_200 = '#a5f2f2';
  const COLOR_AQUA_300 = '#76e5e2';
  const COLOR_AQUA_400 = '#33d6e2';
  const COLOR_AQUA_500 = '#17b8ce';
  const COLOR_AQUA_600 = '#0797ae';
  const COLOR_AQUA_700 = '#0b8599';
  const COLOR_AQUA_800 = '#0f6e84';
  const COLOR_AQUA_900 = '#035e73';
  const COLOR_AQUA_1000 = '#083d4f';
  const COLOR_AQUA_1100 = '#002838';
  const COLOR_BLUE_0 = '#e9f4ff';
  const COLOR_BLUE_100 = '#deebfe';
  const COLOR_BLUE_200 = '#c7dbf9';
  const COLOR_BLUE_300 = '#a1c2f8';
  const COLOR_BLUE_400 = '#679eff';
  const COLOR_BLUE_500 = '#3876e3';
  const COLOR_BLUE_600 = '#2b68d3';
  const COLOR_BLUE_700 = '#205bc3';
  const COLOR_BLUE_800 = '#1150aa';
  const COLOR_BLUE_900 = '#0c3f89';
  const COLOR_BLUE_1000 = '#0a2960';
  const COLOR_BLUE_1100 = '#001738';
  const COLOR_PURPLE_0 = '#f2f2f9';
  const COLOR_PURPLE_100 = '#eaeaf9';
  const COLOR_PURPLE_200 = '#d8d7f9';
  const COLOR_PURPLE_300 = '#c1c1f7';
  const COLOR_PURPLE_400 = '#a193f2';
  const COLOR_PURPLE_500 = '#9180f4';
  const COLOR_PURPLE_600 = '#816fea';
  const COLOR_PURPLE_700 = '#6f5ed3';
  const COLOR_PURPLE_800 = '#5e4eba';
  const COLOR_PURPLE_900 = '#483a9c';
  const COLOR_PURPLE_1000 = '#2d246b';
  const COLOR_PURPLE_1100 = '#1d1d38';
  const COLOR_MAGENTA_0 = '#fef0ff';
  const COLOR_MAGENTA_100 = '#f9e3fc';
  const COLOR_MAGENTA_200 = '#f4c4f7';
  const COLOR_MAGENTA_300 = '#edadf2';
  const COLOR_MAGENTA_350 = '#EC9AF1';
  const COLOR_MAGENTA_400 = '#f282f5';
  const COLOR_MAGENTA_500 = '#db61db';
  const COLOR_MAGENTA_600 = '#c44eb9';
  const COLOR_MAGENTA_700 = '#ac44a8';
  const COLOR_MAGENTA_800 = '#8f3896';
  const COLOR_MAGENTA_900 = '#6c2277';
  const COLOR_MAGENTA_1000 = '#451551';
  const COLOR_MAGENTA_1100 = '#29192d';
  const COLOR_PINK_0 = '#ffe9f3';
  const COLOR_PINK_100 = '#fcdbeb';
  const COLOR_PINK_200 = '#ffb5d5';
  const COLOR_PINK_300 = '#ff95c1';
  const COLOR_PINK_400 = '#ff76ae';
  const COLOR_PINK_500 = '#ef588b';
  const COLOR_PINK_600 = '#e0447c';
  const COLOR_PINK_700 = '#ce3665';
  const COLOR_PINK_800 = '#b22f5b';
  const COLOR_PINK_900 = '#931847';
  const COLOR_PINK_1000 = '#561231';
  const COLOR_PINK_1100 = '#2b1721';
  const COLOR_RED_0 = '#ffeae9';
  const COLOR_RED_100 = '#ffd5d2';
  const COLOR_RED_200 = '#ffb8b1';
  const COLOR_RED_300 = '#ff9c8f';
  const COLOR_RED_400 = '#ff7f6e';
  const COLOR_RED_500 = '#f76054';
  const COLOR_RED_600 = '#ed4c42';
  const COLOR_RED_700 = '#db3e3e';
  const COLOR_RED_800 = '#c63434';
  const COLOR_RED_900 = '#992222';
  const COLOR_RED_1000 = '#6d1313';
  const COLOR_RED_1100 = '#2b1111';
  const COLOR_ORANGE_0 = '#ffede3';
  const COLOR_ORANGE_100 = '#fcdccc';
  const COLOR_ORANGE_200 = '#ffc6a4';
  const COLOR_ORANGE_300 = '#ffb180';
  const COLOR_ORANGE_400 = '#ff9c5d';
  const COLOR_ORANGE_500 = '#fc8943';
  const COLOR_ORANGE_600 = '#f57d33';
  const COLOR_ORANGE_700 = '#ed7024';
  const COLOR_ORANGE_800 = '#ce5511';
  const COLOR_ORANGE_900 = '#962c0b';
  const COLOR_ORANGE_1000 = '#601700';
  const COLOR_ORANGE_1100 = '#2d130e';
  const COLOR_NEUTRAL_0 = '#FFFFFF';
  const COLOR_NEUTRAL_100 = '#f3f4f4';
  const COLOR_NEUTRAL_200 = '#dee1e1';
  const COLOR_NEUTRAL_300 = '#c8cccc';
  const COLOR_NEUTRAL_400 = '#b0b6b7';
  const COLOR_NEUTRAL_500 = '#929a9b';
  const COLOR_NEUTRAL_600 = '#6e797a';
  const COLOR_NEUTRAL_700 = '#515e5f';
  const COLOR_NEUTRAL_800 = '#364141';
  const COLOR_NEUTRAL_900 = '#273333';
  const COLOR_NEUTRAL_1000 = '#162020';
  const COLOR_NEUTRAL_1100 = '#040404';
  const COLOR_YELLOW_0 = '#fff8e2';
  const COLOR_YELLOW_100 = '#fdefcd';
  const COLOR_YELLOW_200 = '#ffe99a';
  const COLOR_YELLOW_300 = '#ffe16e';
  const COLOR_YELLOW_400 = '#ffd943';
  const COLOR_YELLOW_500 = '#ffcd1c';
  const COLOR_YELLOW_600 = '#ffbc00';
  const COLOR_YELLOW_700 = '#dd9903';
  const COLOR_YELLOW_800 = '#ba7506';
  const COLOR_YELLOW_900 = '#944c0c';
  const COLOR_YELLOW_1000 = '#542a00';
  const COLOR_YELLOW_1100 = '#2d1a05';
  const COLOR_BAMBU_TEAL_400 = '#11a7aa';
  const COLOR_BAMBU_TEAL_500 = '#078888';
  const COLOR_BAMBU_TEAL_600 = '#0f6270';
  const COLOR_BAMBU_TEAL_700 = '#0a3f49';
  const COLOR_BAMBU_YELLOW_500 = '#f9b450';
  const COLOR_BAMBU_YELLOW_600 = '#ffa017';

export {
  COLOR_GREEN_0,
  COLOR_GREEN_100,
  COLOR_GREEN_200,
  COLOR_GREEN_300,
  COLOR_GREEN_400,
  COLOR_GREEN_500,
  COLOR_GREEN_600,
  COLOR_GREEN_700,
  COLOR_GREEN_800,
  COLOR_GREEN_900,
  COLOR_GREEN_1000,
  COLOR_GREEN_1100,
  COLOR_TEAL_0,
  COLOR_TEAL_50,
  COLOR_TEAL_100,
  COLOR_TEAL_200,
  COLOR_TEAL_300,
  COLOR_TEAL_400,
  COLOR_TEAL_500,
  COLOR_TEAL_600,
  COLOR_TEAL_700,
  COLOR_TEAL_800,
  COLOR_TEAL_900,
  COLOR_TEAL_1000,
  COLOR_TEAL_1100,
  COLOR_AQUA_0,
  COLOR_AQUA_100,
  COLOR_AQUA_200,
  COLOR_AQUA_300,
  COLOR_AQUA_400,
  COLOR_AQUA_500,
  COLOR_AQUA_600,
  COLOR_AQUA_700,
  COLOR_AQUA_800,
  COLOR_AQUA_900,
  COLOR_AQUA_1000,
  COLOR_AQUA_1100,
  COLOR_BLUE_0,
  COLOR_BLUE_100,
  COLOR_BLUE_200,
  COLOR_BLUE_300,
  COLOR_BLUE_400,
  COLOR_BLUE_500,
  COLOR_BLUE_600,
  COLOR_BLUE_700,
  COLOR_BLUE_800,
  COLOR_BLUE_900,
  COLOR_BLUE_1000,
  COLOR_BLUE_1100,
  COLOR_PURPLE_0,
  COLOR_PURPLE_100,
  COLOR_PURPLE_200,
  COLOR_PURPLE_300,
  COLOR_PURPLE_400,
  COLOR_PURPLE_500,
  COLOR_PURPLE_600,
  COLOR_PURPLE_700,
  COLOR_PURPLE_800,
  COLOR_PURPLE_900,
  COLOR_PURPLE_1000,
  COLOR_PURPLE_1100,
  COLOR_MAGENTA_0,
  COLOR_MAGENTA_100,
  COLOR_MAGENTA_200,
  COLOR_MAGENTA_300,
  COLOR_MAGENTA_350,
  COLOR_MAGENTA_400,
  COLOR_MAGENTA_500,
  COLOR_MAGENTA_600,
  COLOR_MAGENTA_700,
  COLOR_MAGENTA_800,
  COLOR_MAGENTA_900,
  COLOR_MAGENTA_1000,
  COLOR_MAGENTA_1100,
  COLOR_PINK_0,
  COLOR_PINK_100,
  COLOR_PINK_200,
  COLOR_PINK_300,
  COLOR_PINK_400,
  COLOR_PINK_500,
  COLOR_PINK_600,
  COLOR_PINK_700,
  COLOR_PINK_800,
  COLOR_PINK_900,
  COLOR_PINK_1000,
  COLOR_PINK_1100,
  COLOR_RED_0,
  COLOR_RED_100,
  COLOR_RED_200,
  COLOR_RED_300,
  COLOR_RED_400,
  COLOR_RED_500,
  COLOR_RED_600,
  COLOR_RED_700,
  COLOR_RED_800,
  COLOR_RED_900,
  COLOR_RED_1000,
  COLOR_RED_1100,
  COLOR_ORANGE_0,
  COLOR_ORANGE_100,
  COLOR_ORANGE_200,
  COLOR_ORANGE_300,
  COLOR_ORANGE_400,
  COLOR_ORANGE_500,
  COLOR_ORANGE_600,
  COLOR_ORANGE_700,
  COLOR_ORANGE_800,
  COLOR_ORANGE_900,
  COLOR_ORANGE_1000,
  COLOR_ORANGE_1100,
  COLOR_NEUTRAL_0,
  COLOR_NEUTRAL_100,
  COLOR_NEUTRAL_200,
  COLOR_NEUTRAL_300,
  COLOR_NEUTRAL_400,
  COLOR_NEUTRAL_500,
  COLOR_NEUTRAL_600,
  COLOR_NEUTRAL_700,
  COLOR_NEUTRAL_800,
  COLOR_NEUTRAL_900,
  COLOR_NEUTRAL_1000,
  COLOR_NEUTRAL_1100,
  COLOR_YELLOW_0,
  COLOR_YELLOW_100,
  COLOR_YELLOW_200,
  COLOR_YELLOW_300,
  COLOR_YELLOW_400,
  COLOR_YELLOW_500,
  COLOR_YELLOW_600,
  COLOR_YELLOW_700,
  COLOR_YELLOW_800,
  COLOR_YELLOW_900,
  COLOR_YELLOW_1000,
  COLOR_YELLOW_1100,
  COLOR_BAMBU_TEAL_400,
  COLOR_BAMBU_TEAL_500,
  COLOR_BAMBU_TEAL_600,
  COLOR_BAMBU_TEAL_700,
  COLOR_BAMBU_YELLOW_500,
  COLOR_BAMBU_YELLOW_600
};

export default {
  COLOR_GREEN_0,
  COLOR_GREEN_100,
  COLOR_GREEN_200,
  COLOR_GREEN_300,
  COLOR_GREEN_400,
  COLOR_GREEN_500,
  COLOR_GREEN_600,
  COLOR_GREEN_700,
  COLOR_GREEN_800,
  COLOR_GREEN_900,
  COLOR_GREEN_1000,
  COLOR_GREEN_1100,
  COLOR_TEAL_0,
  COLOR_TEAL_50,
  COLOR_TEAL_100,
  COLOR_TEAL_200,
  COLOR_TEAL_300,
  COLOR_TEAL_400,
  COLOR_TEAL_500,
  COLOR_TEAL_600,
  COLOR_TEAL_700,
  COLOR_TEAL_800,
  COLOR_TEAL_900,
  COLOR_TEAL_1000,
  COLOR_TEAL_1100,
  COLOR_AQUA_0,
  COLOR_AQUA_100,
  COLOR_AQUA_200,
  COLOR_AQUA_300,
  COLOR_AQUA_400,
  COLOR_AQUA_500,
  COLOR_AQUA_600,
  COLOR_AQUA_700,
  COLOR_AQUA_800,
  COLOR_AQUA_900,
  COLOR_AQUA_1000,
  COLOR_AQUA_1100,
  COLOR_BLUE_0,
  COLOR_BLUE_100,
  COLOR_BLUE_200,
  COLOR_BLUE_300,
  COLOR_BLUE_400,
  COLOR_BLUE_500,
  COLOR_BLUE_600,
  COLOR_BLUE_700,
  COLOR_BLUE_800,
  COLOR_BLUE_900,
  COLOR_BLUE_1000,
  COLOR_BLUE_1100,
  COLOR_PURPLE_0,
  COLOR_PURPLE_100,
  COLOR_PURPLE_200,
  COLOR_PURPLE_300,
  COLOR_PURPLE_400,
  COLOR_PURPLE_500,
  COLOR_PURPLE_600,
  COLOR_PURPLE_700,
  COLOR_PURPLE_800,
  COLOR_PURPLE_900,
  COLOR_PURPLE_1000,
  COLOR_PURPLE_1100,
  COLOR_MAGENTA_0,
  COLOR_MAGENTA_100,
  COLOR_MAGENTA_200,
  COLOR_MAGENTA_300,
  COLOR_MAGENTA_350,
  COLOR_MAGENTA_400,
  COLOR_MAGENTA_500,
  COLOR_MAGENTA_600,
  COLOR_MAGENTA_700,
  COLOR_MAGENTA_800,
  COLOR_MAGENTA_900,
  COLOR_MAGENTA_1000,
  COLOR_MAGENTA_1100,
  COLOR_PINK_0,
  COLOR_PINK_100,
  COLOR_PINK_200,
  COLOR_PINK_300,
  COLOR_PINK_400,
  COLOR_PINK_500,
  COLOR_PINK_600,
  COLOR_PINK_700,
  COLOR_PINK_800,
  COLOR_PINK_900,
  COLOR_PINK_1000,
  COLOR_PINK_1100,
  COLOR_RED_0,
  COLOR_RED_100,
  COLOR_RED_200,
  COLOR_RED_300,
  COLOR_RED_400,
  COLOR_RED_500,
  COLOR_RED_600,
  COLOR_RED_700,
  COLOR_RED_800,
  COLOR_RED_900,
  COLOR_RED_1000,
  COLOR_RED_1100,
  COLOR_ORANGE_0,
  COLOR_ORANGE_100,
  COLOR_ORANGE_200,
  COLOR_ORANGE_300,
  COLOR_ORANGE_400,
  COLOR_ORANGE_500,
  COLOR_ORANGE_600,
  COLOR_ORANGE_700,
  COLOR_ORANGE_800,
  COLOR_ORANGE_900,
  COLOR_ORANGE_1000,
  COLOR_ORANGE_1100,
  COLOR_NEUTRAL_0,
  COLOR_NEUTRAL_100,
  COLOR_NEUTRAL_200,
  COLOR_NEUTRAL_300,
  COLOR_NEUTRAL_400,
  COLOR_NEUTRAL_500,
  COLOR_NEUTRAL_600,
  COLOR_NEUTRAL_700,
  COLOR_NEUTRAL_800,
  COLOR_NEUTRAL_900,
  COLOR_NEUTRAL_1000,
  COLOR_NEUTRAL_1100,
  COLOR_YELLOW_0,
  COLOR_YELLOW_100,
  COLOR_YELLOW_200,
  COLOR_YELLOW_300,
  COLOR_YELLOW_400,
  COLOR_YELLOW_500,
  COLOR_YELLOW_600,
  COLOR_YELLOW_700,
  COLOR_YELLOW_800,
  COLOR_YELLOW_900,
  COLOR_YELLOW_1000,
  COLOR_YELLOW_1100,
  COLOR_BAMBU_TEAL_400,
  COLOR_BAMBU_TEAL_500,
  COLOR_BAMBU_TEAL_600,
  COLOR_BAMBU_TEAL_700,
  COLOR_BAMBU_YELLOW_500,
  COLOR_BAMBU_YELLOW_600
};