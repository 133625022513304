  const SPACE_SIZE_0 = '0px';
  const SPACE_SIZE_100 = '2px';
  const SPACE_SIZE_200 = '4px';
  const SPACE_SIZE_300 = '8px';
  const SPACE_SIZE_350 = '12px';
  const SPACE_SIZE_400 = '16px';
  const SPACE_SIZE_450 = '24px';
  const SPACE_SIZE_500 = '32px';
  const SPACE_SIZE_600 = '40px';

export {
  SPACE_SIZE_0,
  SPACE_SIZE_100,
  SPACE_SIZE_200,
  SPACE_SIZE_300,
  SPACE_SIZE_350,
  SPACE_SIZE_400,
  SPACE_SIZE_450,
  SPACE_SIZE_500,
  SPACE_SIZE_600
};

export default {
  SPACE_SIZE_0,
  SPACE_SIZE_100,
  SPACE_SIZE_200,
  SPACE_SIZE_300,
  SPACE_SIZE_350,
  SPACE_SIZE_400,
  SPACE_SIZE_450,
  SPACE_SIZE_500,
  SPACE_SIZE_600
};