import { transparentize } from "polished";
import { css } from "styled-components";
import { theme } from "@sproutsocial/seeds-react-theme";

export const svgToDataURL = (svgStr: string) => {
  const encoded = encodeURIComponent(svgStr)
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");

  const header = "data:image/svg+xml,";
  const dataUrl = header + encoded;

  return dataUrl;
};

export const visuallyHidden = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
`;

export const focusRing = css`
  box-shadow: 0 0 0 1px ${theme.colors.button.primary.background.base},
    0 0px 0px 4px
      ${transparentize(0.7, theme.colors.button.primary.background.base)};
  outline: none;

  &::-moz-focus-inner {
    border: 0;
  }
`;

export const pill = css`
  min-width: ${theme.space[600]};
  min-height: ${theme.space[600]};
  padding: ${theme.space[300]};
  border-radius: ${theme.radii.pill};
`;

export const disabled = css`
  opacity: 0.4;
  pointer-events: none;
`;
