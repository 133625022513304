"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.yellow = exports.teal = exports.red = exports.purple = exports.pink = exports.orange = exports.neutral = exports.magenta = exports.green = exports.blue = exports.aqua = void 0;
var _seedsColor = _interopRequireDefault(require("@sproutsocial/seeds-color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var green = exports.green = {
  background: _seedsColor.default.COLOR_GREEN_100,
  highlight: _seedsColor.default.COLOR_GREEN_500,
  foreground: _seedsColor.default.COLOR_GREEN_900
};
var blue = exports.blue = {
  background: _seedsColor.default.COLOR_BLUE_100,
  highlight: _seedsColor.default.COLOR_BLUE_600,
  foreground: _seedsColor.default.COLOR_BLUE_900
};
var purple = exports.purple = {
  background: _seedsColor.default.COLOR_PURPLE_100,
  highlight: _seedsColor.default.COLOR_PURPLE_500,
  foreground: _seedsColor.default.COLOR_PURPLE_800
};
var yellow = exports.yellow = {
  background: _seedsColor.default.COLOR_YELLOW_100,
  highlight: _seedsColor.default.COLOR_YELLOW_600,
  foreground: _seedsColor.default.COLOR_YELLOW_900
};
var orange = exports.orange = {
  background: _seedsColor.default.COLOR_ORANGE_100,
  highlight: _seedsColor.default.COLOR_ORANGE_600,
  foreground: _seedsColor.default.COLOR_ORANGE_900
};
var red = exports.red = {
  background: _seedsColor.default.COLOR_RED_100,
  highlight: _seedsColor.default.COLOR_RED_600,
  foreground: _seedsColor.default.COLOR_RED_900
};
var neutral = exports.neutral = {
  background: _seedsColor.default.COLOR_NEUTRAL_100,
  highlight: _seedsColor.default.COLOR_NEUTRAL_600,
  foreground: _seedsColor.default.COLOR_NEUTRAL_900
};
var magenta = exports.magenta = {
  background: _seedsColor.default.COLOR_MAGENTA_100,
  highlight: _seedsColor.default.COLOR_MAGENTA_600,
  foreground: _seedsColor.default.COLOR_MAGENTA_900
};
var pink = exports.pink = {
  background: _seedsColor.default.COLOR_PINK_100,
  highlight: _seedsColor.default.COLOR_PINK_600,
  foreground: _seedsColor.default.COLOR_PINK_900
};
var aqua = exports.aqua = {
  background: _seedsColor.default.COLOR_AQUA_100,
  highlight: _seedsColor.default.COLOR_AQUA_500,
  foreground: _seedsColor.default.COLOR_AQUA_900
};
var teal = exports.teal = {
  background: _seedsColor.default.COLOR_TEAL_100,
  highlight: _seedsColor.default.COLOR_TEAL_500,
  foreground: _seedsColor.default.COLOR_TEAL_900
};