"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datavizPalette = void 0;
var _seedsColor = _interopRequireDefault(require("@sproutsocial/seeds-color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var datavizPalette = exports.datavizPalette = {
  DATAVIZ_COLORS_LIST: [_seedsColor.default.COLOR_TEAL_500, _seedsColor.default.COLOR_PURPLE_700, _seedsColor.default.COLOR_PINK_700, _seedsColor.default.COLOR_YELLOW_500, _seedsColor.default.COLOR_BLUE_500, _seedsColor.default.COLOR_MAGENTA_500, _seedsColor.default.COLOR_GREEN_500, _seedsColor.default.COLOR_ORANGE_500, _seedsColor.default.COLOR_RED_700, _seedsColor.default.COLOR_TEAL_900, _seedsColor.default.COLOR_PURPLE_400, _seedsColor.default.COLOR_PINK_900, _seedsColor.default.COLOR_YELLOW_900, _seedsColor.default.COLOR_BLUE_900, _seedsColor.default.COLOR_MAGENTA_900, _seedsColor.default.COLOR_GREEN_900, _seedsColor.default.COLOR_ORANGE_900, _seedsColor.default.COLOR_RED_400, _seedsColor.default.COLOR_GREEN_700, _seedsColor.default.COLOR_YELLOW_800],
  DATAVIZ_COLORS_MAP: {
    "1": _seedsColor.default.COLOR_TEAL_500,
    "2": _seedsColor.default.COLOR_PURPLE_700,
    "3": _seedsColor.default.COLOR_PINK_700,
    "4": _seedsColor.default.COLOR_YELLOW_500,
    "5": _seedsColor.default.COLOR_BLUE_500,
    "6": _seedsColor.default.COLOR_MAGENTA_500,
    "7": _seedsColor.default.COLOR_GREEN_500,
    "8": _seedsColor.default.COLOR_ORANGE_500,
    "9": _seedsColor.default.COLOR_RED_700,
    "10": _seedsColor.default.COLOR_TEAL_900,
    "11": _seedsColor.default.COLOR_PURPLE_400,
    "12": _seedsColor.default.COLOR_PINK_900,
    "13": _seedsColor.default.COLOR_YELLOW_900,
    "14": _seedsColor.default.COLOR_BLUE_900,
    "15": _seedsColor.default.COLOR_MAGENTA_900,
    "16": _seedsColor.default.COLOR_GREEN_900,
    "17": _seedsColor.default.COLOR_ORANGE_900,
    "18": _seedsColor.default.COLOR_RED_400,
    "19": _seedsColor.default.COLOR_GREEN_700,
    "20": _seedsColor.default.COLOR_YELLOW_800
  }
};