import styled, { css } from "styled-components";
import {
  COMMON,
  LAYOUT,
  FLEXBOX,
  GRID,
} from "@sproutsocial/seeds-react-system-props";
import { focusRing, disabled, pill } from "@sproutsocial/seeds-react-mixins";
import { IconContainer } from "@sproutsocial/seeds-react-icon";
import type { TypeButtonProps, TypeButtonAppearance } from "./index";
interface TypeButtonStyleProps extends TypeButtonProps {
  appearance: TypeButtonAppearance;
  buttonSize?: "large" | "default";
}
const Container = styled.button<TypeButtonStyleProps>`
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily};
  border: 1px solid
    ${(props) => props.theme.colors.button[props.appearance].border.base};
  border-radius: ${(props) => props.theme.radii[500]};
  border-style: ${(props) =>
    props.appearance === "placeholder" ? "dashed" : "solid"};
  background: ${(props) =>
    props.theme.colors.button[props.appearance].background.base};
  color: ${(props) => props.theme.colors.button[props.appearance].text.base};
  cursor: pointer;
  text-decoration: none;
  /**
   * Matches default line height of Icon. Also matches the overall height of
   * Input and Select.
   */
  line-height: 16px;
  white-space: nowrap;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  transition: all ${(props) => props.theme.duration.fast} linear;

  margin: 0;
  padding: ${(props) =>
    props.buttonSize === "default"
      ? `${props.theme.space[300]}`
      : `${props.theme.space[350]}`};

  font-size: ${(props) =>
    props.buttonSize === "default"
      ? props.theme.typography[200].fontSize
      : props.theme.typography[300].fontSize};

  &:visited {
    color: ${(props) => props.theme.colors.button[props.appearance].text.base};
  }

  &:hover {
    color: ${(props) => props.theme.colors.button[props.appearance].text.hover};
    ${(props) =>
      props.appearance !== "unstyled" &&
      `background: ${
        props.theme.colors.button[props.appearance].background.hover
      }`};
    text-decoration: none;
    box-shadow: ${(props) =>
      props.appearance === "placeholder" ? props.theme.shadows.low : "none"};
  }

  &:active {
    color: ${(props) => props.theme.colors.button[props.appearance].text.hover};
    ${(props) =>
      props.appearance !== "unstyled" &&
      `background: ${
        props.theme.colors.button[props.appearance].background.active
      }`};
    transform: translateY(1px);
  }

  &:focus {
    ${focusRing}
  }

  &:focus:active {
    box-shadow: none;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${props.theme.colors.button[props.appearance].text
        .hover} !important;
      ${props.appearance !== "unstyled" &&
      `background: ${
        props.theme.colors.button[props.appearance].background.active
      } !important`};
    `}

  ${(props) => props.disabled && disabled}

  ${(props) =>
    props.appearance === "pill" &&
    css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      ${pill}

      &:focus:active,
      &:hover {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 1px
          ${props.theme.colors.button.pill.border.hover};
      }
    `}

  ${IconContainer} {
    vertical-align: text-bottom;
  }

  ${LAYOUT}
  ${COMMON}
  ${FLEXBOX}
  ${GRID}
`;
Container.displayName = "Button-Container";
export default Container; //${props.theme.mode === "dark" ? "screen" : "multiply"}
