// eslint-disable-next-line no-restricted-syntax
import * as styles from "styled-system";

export const gap = styles.system({
  gap: {
    property: "gap",
    scale: "space",
  },
  rowGap: {
    property: "rowGap",
    scale: "space",
  },
  columnGap: {
    property: "columnGap",
    scale: "space",
  },
});

const typeScale = styles.variant({
  key: "typography",
  prop: "typeScale",
});

export const COMMON = styles.compose(
  styles.color,
  styles.space,
  styles.system({
    cursor: true,
    whiteSpace: true,
  })
);

export const BORDER = styles.compose(
  styles.border,
  styles.borderTop,
  styles.borderRight,
  styles.borderBottom,
  styles.borderLeft,
  styles.borderWidth,
  styles.borderStyle,
  styles.borderColor,
  styles.borderRadius,
  // After reading the docs, these seem duplicate to styles.border
  // styles.border.borderBottomLeftRadius,
  // styles.border.borderBottomRightRadius,
  // styles.border.borderTopLeftRadius,
  // styles.border.borderTopRightRadius,
  styles.boxShadow
);

export const TYPOGRAPHY = styles.compose(
  typeScale,
  styles.fontFamily,
  styles.fontStyle,
  styles.fontWeight,
  styles.lineHeight,
  styles.textAlign
);

export const LAYOUT = styles.compose(
  styles.display,
  styles.size,
  styles.width,
  styles.height,
  styles.minWidth,
  styles.minHeight,
  styles.maxWidth,
  styles.maxHeight,
  styles.overflow,
  styles.verticalAlign
);

export const POSITION = styles.compose(
  styles.position,
  styles.zIndex,
  styles.top,
  styles.right,
  styles.bottom,
  styles.left
);

export const FLEXBOX = styles.compose(
  styles.flexBasis,
  styles.flexDirection,
  styles.flexWrap,
  styles.alignContent,
  styles.alignItems,
  styles.justifyContent,
  styles.justifyItems,
  styles.order,
  styles.flex,
  styles.flexShrink,
  styles.flexGrow,
  styles.justifySelf,
  styles.alignSelf,
  gap
);

export const GRID = styles.grid;
