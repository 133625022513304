"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _seedsColor = _interopRequireDefault(require("@sproutsocial/seeds-color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var literalColors = {
  neutral: {
    "0": _seedsColor.default.COLOR_NEUTRAL_0,
    "100": _seedsColor.default.COLOR_NEUTRAL_100,
    "200": _seedsColor.default.COLOR_NEUTRAL_200,
    "300": _seedsColor.default.COLOR_NEUTRAL_300,
    "400": _seedsColor.default.COLOR_NEUTRAL_400,
    "500": _seedsColor.default.COLOR_NEUTRAL_500,
    "600": _seedsColor.default.COLOR_NEUTRAL_600,
    "700": _seedsColor.default.COLOR_NEUTRAL_700,
    "800": _seedsColor.default.COLOR_NEUTRAL_800,
    "900": _seedsColor.default.COLOR_NEUTRAL_900,
    "1000": _seedsColor.default.COLOR_NEUTRAL_1000,
    "1100": _seedsColor.default.COLOR_NEUTRAL_1100
  },
  green: {
    "0": _seedsColor.default.COLOR_GREEN_0,
    "100": _seedsColor.default.COLOR_GREEN_100,
    "200": _seedsColor.default.COLOR_GREEN_200,
    "300": _seedsColor.default.COLOR_GREEN_300,
    "400": _seedsColor.default.COLOR_GREEN_400,
    "500": _seedsColor.default.COLOR_GREEN_500,
    "600": _seedsColor.default.COLOR_GREEN_600,
    "700": _seedsColor.default.COLOR_GREEN_700,
    "800": _seedsColor.default.COLOR_GREEN_800,
    "900": _seedsColor.default.COLOR_GREEN_900,
    "1000": _seedsColor.default.COLOR_GREEN_1000,
    "1100": _seedsColor.default.COLOR_GREEN_1100
  },
  red: {
    "0": _seedsColor.default.COLOR_RED_0,
    "100": _seedsColor.default.COLOR_RED_100,
    "200": _seedsColor.default.COLOR_RED_200,
    "300": _seedsColor.default.COLOR_RED_300,
    "400": _seedsColor.default.COLOR_RED_400,
    "500": _seedsColor.default.COLOR_RED_500,
    "600": _seedsColor.default.COLOR_RED_600,
    "700": _seedsColor.default.COLOR_RED_700,
    "800": _seedsColor.default.COLOR_RED_800,
    "900": _seedsColor.default.COLOR_RED_900,
    "1000": _seedsColor.default.COLOR_RED_1000,
    "1100": _seedsColor.default.COLOR_RED_1100
  },
  blue: {
    "0": _seedsColor.default.COLOR_BLUE_0,
    "100": _seedsColor.default.COLOR_BLUE_100,
    "200": _seedsColor.default.COLOR_BLUE_200,
    "300": _seedsColor.default.COLOR_BLUE_300,
    "400": _seedsColor.default.COLOR_BLUE_400,
    "500": _seedsColor.default.COLOR_BLUE_500,
    "600": _seedsColor.default.COLOR_BLUE_600,
    "700": _seedsColor.default.COLOR_BLUE_700,
    "800": _seedsColor.default.COLOR_BLUE_800,
    "900": _seedsColor.default.COLOR_BLUE_900,
    "1000": _seedsColor.default.COLOR_BLUE_1000,
    "1100": _seedsColor.default.COLOR_BLUE_1100
  },
  teal: {
    "0": _seedsColor.default.COLOR_TEAL_0,
    "50": _seedsColor.default.COLOR_TEAL_50,
    "100": _seedsColor.default.COLOR_TEAL_100,
    "200": _seedsColor.default.COLOR_TEAL_200,
    "300": _seedsColor.default.COLOR_TEAL_300,
    "400": _seedsColor.default.COLOR_TEAL_400,
    "500": _seedsColor.default.COLOR_TEAL_500,
    "600": _seedsColor.default.COLOR_TEAL_600,
    "700": _seedsColor.default.COLOR_TEAL_700,
    "800": _seedsColor.default.COLOR_TEAL_800,
    "900": _seedsColor.default.COLOR_TEAL_900,
    "1000": _seedsColor.default.COLOR_TEAL_1000,
    "1100": _seedsColor.default.COLOR_TEAL_1100
  },
  aqua: {
    "0": _seedsColor.default.COLOR_AQUA_0,
    "100": _seedsColor.default.COLOR_AQUA_100,
    "200": _seedsColor.default.COLOR_AQUA_200,
    "300": _seedsColor.default.COLOR_AQUA_300,
    "400": _seedsColor.default.COLOR_AQUA_400,
    "500": _seedsColor.default.COLOR_AQUA_500,
    "600": _seedsColor.default.COLOR_AQUA_600,
    "700": _seedsColor.default.COLOR_AQUA_700,
    "800": _seedsColor.default.COLOR_AQUA_800,
    "900": _seedsColor.default.COLOR_AQUA_900,
    "1000": _seedsColor.default.COLOR_AQUA_1000,
    "1100": _seedsColor.default.COLOR_AQUA_1100
  },
  purple: {
    "0": _seedsColor.default.COLOR_PURPLE_0,
    "100": _seedsColor.default.COLOR_PURPLE_100,
    "200": _seedsColor.default.COLOR_PURPLE_200,
    "300": _seedsColor.default.COLOR_PURPLE_300,
    "400": _seedsColor.default.COLOR_PURPLE_400,
    "500": _seedsColor.default.COLOR_PURPLE_500,
    "600": _seedsColor.default.COLOR_PURPLE_600,
    "700": _seedsColor.default.COLOR_PURPLE_700,
    "800": _seedsColor.default.COLOR_PURPLE_800,
    "900": _seedsColor.default.COLOR_PURPLE_900,
    "1000": _seedsColor.default.COLOR_PURPLE_1000,
    "1100": _seedsColor.default.COLOR_PURPLE_1100
  },
  magenta: {
    "0": _seedsColor.default.COLOR_MAGENTA_0,
    "100": _seedsColor.default.COLOR_MAGENTA_100,
    "200": _seedsColor.default.COLOR_MAGENTA_200,
    "300": _seedsColor.default.COLOR_MAGENTA_300,
    "350": _seedsColor.default.COLOR_MAGENTA_350,
    "400": _seedsColor.default.COLOR_MAGENTA_400,
    "500": _seedsColor.default.COLOR_MAGENTA_500,
    "600": _seedsColor.default.COLOR_MAGENTA_600,
    "700": _seedsColor.default.COLOR_MAGENTA_700,
    "800": _seedsColor.default.COLOR_MAGENTA_800,
    "900": _seedsColor.default.COLOR_MAGENTA_900,
    "1000": _seedsColor.default.COLOR_MAGENTA_1000,
    "1100": _seedsColor.default.COLOR_MAGENTA_1100
  },
  yellow: {
    "0": _seedsColor.default.COLOR_YELLOW_0,
    "100": _seedsColor.default.COLOR_YELLOW_100,
    "200": _seedsColor.default.COLOR_YELLOW_200,
    "300": _seedsColor.default.COLOR_YELLOW_300,
    "400": _seedsColor.default.COLOR_YELLOW_400,
    "500": _seedsColor.default.COLOR_YELLOW_500,
    "600": _seedsColor.default.COLOR_YELLOW_600,
    "700": _seedsColor.default.COLOR_YELLOW_700,
    "800": _seedsColor.default.COLOR_YELLOW_800,
    "900": _seedsColor.default.COLOR_YELLOW_900,
    "1000": _seedsColor.default.COLOR_YELLOW_1000,
    "1100": _seedsColor.default.COLOR_YELLOW_1100
  },
  pink: {
    "0": _seedsColor.default.COLOR_PINK_0,
    "100": _seedsColor.default.COLOR_PINK_100,
    "200": _seedsColor.default.COLOR_PINK_200,
    "300": _seedsColor.default.COLOR_PINK_300,
    "400": _seedsColor.default.COLOR_PINK_400,
    "500": _seedsColor.default.COLOR_PINK_500,
    "600": _seedsColor.default.COLOR_PINK_600,
    "700": _seedsColor.default.COLOR_PINK_700,
    "800": _seedsColor.default.COLOR_PINK_800,
    "900": _seedsColor.default.COLOR_PINK_900,
    "1000": _seedsColor.default.COLOR_PINK_1000,
    "1100": _seedsColor.default.COLOR_PINK_1100
  },
  orange: {
    "0": _seedsColor.default.COLOR_ORANGE_0,
    "100": _seedsColor.default.COLOR_ORANGE_100,
    "200": _seedsColor.default.COLOR_ORANGE_200,
    "300": _seedsColor.default.COLOR_ORANGE_300,
    "400": _seedsColor.default.COLOR_ORANGE_400,
    "500": _seedsColor.default.COLOR_ORANGE_500,
    "600": _seedsColor.default.COLOR_ORANGE_600,
    "700": _seedsColor.default.COLOR_ORANGE_700,
    "800": _seedsColor.default.COLOR_ORANGE_800,
    "900": _seedsColor.default.COLOR_ORANGE_900,
    "1000": _seedsColor.default.COLOR_ORANGE_1000,
    "1100": _seedsColor.default.COLOR_ORANGE_1100
  }
};
var _default = exports.default = literalColors;