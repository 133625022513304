"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navigation = exports.listening = exports.growth = exports.default = exports.datePicker = exports.cardControl = exports.analytics = exports.ai = void 0;
var _polished = require("polished");
var _theme = _interopRequireDefault(require("../../../light/theme"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* eslint-disable no-restricted-imports */ // json-to-scss is not working with the alias right now
//import {theme as baseLightTheme, type TypeSproutTheme} from '@src';
var navigation = exports.navigation = {
  main: {
    background: {
      base: _theme.default.colors.neutral[900],
      overflowGradient: _theme.default.colors.neutral[1000]
    },
    border: {
      base: _theme.default.colors.neutral[1000]
    }
  },
  secondary: {
    background: {
      base: _theme.default.colors.neutral[800]
    },
    widget: {
      background: {
        base: _theme.default.colors.neutral[1000]
      }
    },
    accordion: {
      background: {
        base: _theme.default.colors.neutral[900]
      }
    }
  },
  settings: {
    listItem: {
      background: {
        base: "transparent",
        hover: _theme.default.colors.neutral[200],
        selected: _theme.default.colors.neutral[0]
      }
    }
  },
  text: {
    base: _theme.default.colors.neutral[0],
    hover: _theme.default.colors.neutral[0]
  },
  icon: {
    base: _theme.default.colors.neutral[0],
    hover: _theme.default.colors.neutral[0]
  },
  listItem: {
    background: {
      base: _theme.default.colors.neutral[800],
      hover: _theme.default.colors.neutral[1000],
      selected: _theme.default.colors.neutral[700]
    }
  }
};
var datePicker = exports.datePicker = {
  comparison: {
    background: {
      base: _theme.default.colors.neutral[400]
    },
    text: {
      base: _theme.default.colors.neutral[800]
    }
  }
};
var ai = exports.ai = {
  feature: {
    decorative: {
      // these two colors form a gradient in AI features
      primary: _theme.default.colors.teal[800],
      secondary: _theme.default.colors.blue[900]
    },
    background: {
      primary: _theme.default.colors.teal[800],
      secondary: _theme.default.colors.blue[900],
      default: {
        primary: _theme.default.colors.teal[800],
        secondary: _theme.default.colors.blue[900]
      },
      inverse: {
        primary: _theme.default.colors.teal[100],
        secondary: _theme.default.colors.blue[200]
      }
    }
  }
};
var analytics = exports.analytics = {
  trend: {
    positive: _theme.default.colors.teal[800],
    neutral: _theme.default.colors.neutral[800],
    negative: _theme.default.colors.neutral[800]
  },
  overlay: {
    background: {
      base: (0, _polished.transparentize)(0.2, _theme.default.colors.neutral[0])
    }
  }
};
var listening = exports.listening = {
  chart: {
    indicator: {
      default: {
        primary: _theme.default.colors.neutral[400],
        secondary: _theme.default.colors.neutral[0]
      },
      hover: {
        primary: _theme.default.colors.neutral[1000],
        secondary: _theme.default.colors.neutral[0]
      }
    },
    spike: {
      background: {
        base: _theme.default.colors.teal[500]
      },
      icon: {
        base: _theme.default.colors.neutral[0]
      }
    }
  },
  topicTypes: {
    customTopic: _theme.default.colors.blue[400],
    brandHealth: _theme.default.colors.red[400],
    industryInsights: _theme.default.colors.green[400],
    competitiveAnalysis: _theme.default.colors.yellow[400],
    campaignAnalysis: _theme.default.colors.magenta[400],
    eventMonitoring: _theme.default.colors.aqua[400],
    featuredTopic: _theme.default.colors.green[400]
  },
  worldMap: {
    empty: _theme.default.colors.neutral[200],
    q0: _theme.default.colors.purple[200],
    q1: _theme.default.colors.purple[300],
    q2: _theme.default.colors.purple[400],
    q3: _theme.default.colors.purple[500],
    q4: _theme.default.colors.purple[600],
    q5: _theme.default.colors.purple[700],
    q6: _theme.default.colors.purple[800],
    q7: _theme.default.colors.purple[900]
  }
};
var growth = exports.growth = {
  carousel: {
    indicator: {
      active: _theme.default.colors.blue[600],
      inactive: _theme.default.colors.neutral[300]
    }
  },
  education: {
    decorative: {
      aqua: _theme.default.colors.aqua[600],
      teal: _theme.default.colors.teal[700]
    }
  },
  opportunity: {
    background: {
      base: _theme.default.colors.purple[700],
      secondary: _theme.default.colors.neutral[900],
      hover: _theme.default.colors.purple[300]
    },
    button: {
      primary: {
        base: _theme.default.colors.purple[700],
        hover: _theme.default.colors.purple[800]
      }
    },
    badge: {
      background: {
        base: _theme.default.colors.purple[200],
        active: _theme.default.colors.purple[800]
      },
      icon: {
        base: _theme.default.colors.purple[700],
        active: _theme.default.colors.purple[100]
      },
      text: {
        base: _theme.default.colors.purple[700]
      }
    },
    decorative: {
      // confetti
      green: _theme.default.colors.green[700],
      lightAqua: _theme.default.colors.aqua[600],
      darkAqua: _theme.default.colors.aqua[1100],
      purple: _theme.default.colors.purple[700]
    }
  },
  featuredDemo: {
    background: {
      primary: {
        base: _theme.default.colors.blue[700],
        hover: _theme.default.colors.blue[900]
      },
      secondary: {
        base: _theme.default.colors.purple[800],
        hover: _theme.default.colors.purple[800]
      }
    }
  },
  darkModal: {
    background: {
      base: _theme.default.colors.aqua[1100]
    },
    text: {
      base: _theme.default.colors.neutral[0]
    },
    cards: {
      background: {
        base: _theme.default.colors.neutral[0],
        hover: _theme.default.colors.purple[100]
      },
      text: {
        base: _theme.default.colors.neutral[800],
        hover: _theme.default.colors.neutral[900]
      },
      border: {
        base: _theme.default.colors.neutral[0],
        hover: _theme.default.colors.purple[700]
      }
    }
  },
  user: {
    status: {
      online: _theme.default.colors.green[700]
    }
  }
};
var cardControl = exports.cardControl = {
  background: {
    base: _theme.default.colors.neutral[0],
    selected: _theme.default.colors.neutral[800],
    hover: _theme.default.colors.neutral[100]
  },
  text: {
    selected: _theme.default.colors.text.inverse
  }
};
var lightTheme = _objectSpread(_objectSpread({}, _theme.default), {}, {
  colors: _objectSpread(_objectSpread({}, _theme.default.colors), {}, {
    ai: ai,
    navigation: navigation,
    datePicker: datePicker,
    analytics: analytics,
    listening: listening,
    growth: growth,
    cardControl: cardControl
  })
});
var _default = exports.default = lightTheme;