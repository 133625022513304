"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shadows = exports.default = void 0;
var _polished = require("polished");
var _seedsColor = _interopRequireDefault(require("@sproutsocial/seeds-color"));
var _seedsDepth = _interopRequireDefault(require("@sproutsocial/seeds-depth"));
var _theme = _interopRequireDefault(require("../light/theme"));
var _datavizPalette = require("./dataviz-palette");
var _decorativePalettes = require("./decorative-palettes");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* eslint-disable no-restricted-imports */ // json-to-scss is not working with the alias right now
//import {theme as lightTheme} from '@src';
//import {TypeThemeMode} from '@src/types/theme';
var MODE = "dark";
var shadows = exports.shadows = {
  low: "".concat(_seedsDepth.default.ELEVATION_LEVEL_100, " ").concat(_seedsColor.default.COLOR_NEUTRAL_1100, "FF"),
  medium: "".concat(_seedsDepth.default.ELEVATION_LEVEL_300, " ").concat(_seedsColor.default.COLOR_NEUTRAL_1100, "FF"),
  high: "".concat(_seedsDepth.default.ELEVATION_LEVEL_400, " ").concat(_seedsColor.default.COLOR_NEUTRAL_1100, "FF")
};

// If you are making changes to the colors in the theme file tag the Design Systems team on your PR! Thank you!!

var colors = _objectSpread(_objectSpread({}, _theme.default.colors), {}, {
  app: {
    background: {
      base: _seedsColor.default.COLOR_NEUTRAL_1000
    }
  },
  container: {
    background: {
      base: _seedsColor.default.COLOR_NEUTRAL_900,
      success: _decorativePalettes.green.background,
      warning: _decorativePalettes.yellow.background,
      error: _decorativePalettes.red.background,
      info: _decorativePalettes.blue.background,
      opportunity: _decorativePalettes.purple.background,
      /**  @deprecated Use "error" instead of "danger" */
      danger: _decorativePalettes.red.background,
      decorative: {
        green: _decorativePalettes.green.background,
        blue: _decorativePalettes.blue.background,
        purple: _decorativePalettes.purple.background,
        yellow: _decorativePalettes.yellow.background,
        orange: _decorativePalettes.orange.background,
        red: _decorativePalettes.red.background,
        neutral: _decorativePalettes.neutral.background,
        magenta: _decorativePalettes.magenta.background,
        pink: _decorativePalettes.pink.background,
        aqua: _decorativePalettes.aqua.background,
        teal: _decorativePalettes.teal.background
      },
      selected: _seedsColor.default.COLOR_NEUTRAL_0,
      positive_sentiment: _seedsColor.default.COLOR_BLUE_500,
      negative_sentiment: _seedsColor.default.COLOR_RED_500,
      neutral_sentiment: _seedsColor.default.COLOR_NEUTRAL_300
    },
    border: {
      base: _seedsColor.default.COLOR_NEUTRAL_1100,
      success: _decorativePalettes.green.highlight,
      warning: _decorativePalettes.yellow.highlight,
      error: _decorativePalettes.red.highlight,
      /**  @deprecated Use "error" instead of "danger" */
      danger: _decorativePalettes.red.highlight,
      info: _decorativePalettes.blue.highlight,
      opportunity: _decorativePalettes.purple.highlight,
      decorative: {
        green: _decorativePalettes.green.highlight,
        blue: _decorativePalettes.blue.highlight,
        purple: _decorativePalettes.purple.highlight,
        yellow: _decorativePalettes.yellow.highlight,
        orange: _decorativePalettes.orange.highlight,
        red: _decorativePalettes.red.highlight,
        neutral: _decorativePalettes.neutral.highlight,
        magenta: _decorativePalettes.magenta.highlight,
        pink: _decorativePalettes.pink.highlight,
        aqua: _decorativePalettes.aqua.highlight,
        teal: _decorativePalettes.teal.highlight
      },
      selected: _seedsColor.default.COLOR_NEUTRAL_0
    }
  },
  button: {
    primary: {
      background: {
        base: _seedsColor.default.COLOR_BLUE_400,
        hover: _seedsColor.default.COLOR_BLUE_300,
        active: _seedsColor.default.COLOR_BLUE_200
      },
      border: {
        base: "transparent"
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_900,
        hover: _seedsColor.default.COLOR_NEUTRAL_1000
      }
    },
    secondary: {
      background: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_100,
        active: _seedsColor.default.COLOR_NEUTRAL_0
      },
      border: {
        base: _seedsColor.default.COLOR_NEUTRAL_0
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_0,
        hover: _seedsColor.default.COLOR_NEUTRAL_800
      }
    },
    pill: {
      background: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_1000,
        active: _seedsColor.default.COLOR_NEUTRAL_900
      },
      border: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_0
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_100,
        hover: _seedsColor.default.COLOR_NEUTRAL_0
      }
    },
    destructive: {
      background: {
        base: _seedsColor.default.COLOR_RED_400,
        hover: _seedsColor.default.COLOR_RED_300,
        active: _seedsColor.default.COLOR_RED_200
      },
      border: {
        base: "transparent"
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_900,
        hover: _seedsColor.default.COLOR_NEUTRAL_1000
      }
    },
    placeholder: {
      background: {
        base: "transparent",
        hover: _seedsColor.default.COLOR_NEUTRAL_1100,
        active: _seedsColor.default.COLOR_NEUTRAL_1100
      },
      border: {
        base: _seedsColor.default.COLOR_NEUTRAL_500
      },
      text: {
        base: _seedsColor.default.COLOR_BLUE_400,
        hover: _seedsColor.default.COLOR_BLUE_300
      }
    },
    unstyled: {
      background: {
        base: "transparent"
      },
      border: {
        base: "transparent"
      },
      text: {
        base: _seedsColor.default.COLOR_NEUTRAL_300,
        hover: _seedsColor.default.COLOR_NEUTRAL_200
      }
    }
  },
  link: {
    base: _seedsColor.default.COLOR_BLUE_400,
    hover: _seedsColor.default.COLOR_BLUE_300
  },
  text: {
    headline: _seedsColor.default.COLOR_NEUTRAL_0,
    subtext: _seedsColor.default.COLOR_NEUTRAL_200,
    body: _seedsColor.default.COLOR_NEUTRAL_100,
    inverse: _seedsColor.default.COLOR_NEUTRAL_900,
    error: _decorativePalettes.red.foreground,
    background: {
      highlight: _seedsColor.default.COLOR_YELLOW_900,
      selection: _seedsColor.default.COLOR_BLUE_800
    },
    decorative: {
      green: _decorativePalettes.green.foreground,
      blue: _decorativePalettes.blue.foreground,
      purple: _decorativePalettes.purple.foreground,
      yellow: _decorativePalettes.yellow.foreground,
      orange: _decorativePalettes.orange.foreground,
      red: _decorativePalettes.red.foreground,
      neutral: _decorativePalettes.neutral.foreground,
      magenta: _decorativePalettes.magenta.foreground,
      pink: _decorativePalettes.pink.foreground,
      aqua: _decorativePalettes.aqua.foreground,
      teal: _decorativePalettes.teal.foreground
    }
  },
  icon: {
    base: _seedsColor.default.COLOR_NEUTRAL_100,
    inverse: _seedsColor.default.COLOR_NEUTRAL_900,
    success: _decorativePalettes.green.foreground,
    warning: _decorativePalettes.yellow.foreground,
    error: _decorativePalettes.red.foreground,
    /**  @deprecated Use "error" instead of "danger" */
    danger: _decorativePalettes.red.foreground,
    info: _decorativePalettes.blue.foreground,
    opportunity: _decorativePalettes.purple.foreground,
    applied: _seedsColor.default.COLOR_BLUE_400,
    positive_sentiment: _seedsColor.default.COLOR_BLUE_600,
    negative_sentiment: _seedsColor.default.COLOR_RED_600,
    neutral_sentiment: _seedsColor.default.COLOR_NEUTRAL_500
  },
  form: {
    background: {
      base: _seedsColor.default.COLOR_NEUTRAL_900,
      selected: _seedsColor.default.COLOR_NEUTRAL_0
    },
    border: {
      base: _seedsColor.default.COLOR_NEUTRAL_500,
      error: _decorativePalettes.red.highlight,
      warning: _decorativePalettes.yellow.highlight,
      selected: _seedsColor.default.COLOR_NEUTRAL_0
    },
    placeholder: {
      base: _seedsColor.default.COLOR_NEUTRAL_500
    }
  },
  listItem: {
    background: {
      base: "transparent",
      hover: _seedsColor.default.COLOR_NEUTRAL_800,
      selected: _seedsColor.default.COLOR_NEUTRAL_0
    }
  },
  overlay: {
    background: {
      base: (0, _polished.transparentize)(0.28, _seedsColor.default.COLOR_NEUTRAL_1000)
    },
    text: {
      base: _seedsColor.default.COLOR_NEUTRAL_0
    },
    icon: {
      base: _seedsColor.default.COLOR_NEUTRAL_0
    }
  },
  elevation: {
    base: _seedsColor.default.COLOR_NEUTRAL_1100
  },
  illustration: {
    fill: _seedsColor.default.COLOR_BLUE_400,
    stroke: _decorativePalettes.neutral.foreground
  },
  dataviz: {
    map: _datavizPalette.datavizPalette.DATAVIZ_COLORS_MAP,
    list: _datavizPalette.datavizPalette.DATAVIZ_COLORS_LIST
  }
}, _datavizPalette.datavizPalette);
var darkTheme = _objectSpread(_objectSpread({}, _theme.default), {}, {
  colors: colors,
  shadows: shadows,
  mode: MODE
});
var _default = exports.default = darkTheme;