"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  theme: true,
  darkTheme: true,
  sproutLightTheme: true,
  sproutDarkTheme: true
};
Object.defineProperty(exports, "darkTheme", {
  enumerable: true,
  get: function get() {
    return _theme2.default;
  }
});
Object.defineProperty(exports, "sproutDarkTheme", {
  enumerable: true,
  get: function get() {
    return _sproutTheme.sproutDarkTheme;
  }
});
Object.defineProperty(exports, "sproutLightTheme", {
  enumerable: true,
  get: function get() {
    return _sproutTheme.sproutLightTheme;
  }
});
Object.defineProperty(exports, "theme", {
  enumerable: true,
  get: function get() {
    return _theme.default;
  }
});
var _theme = _interopRequireDefault(require("./light/theme"));
var _theme2 = _interopRequireDefault(require("./dark/theme"));
var _sproutTheme = require("./extendedThemes/sproutTheme");
var _theme3 = require("./types/theme");
Object.keys(_theme3).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _theme3[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _theme3[key];
    }
  });
});
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }