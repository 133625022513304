"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "sproutDarkTheme", {
  enumerable: true,
  get: function get() {
    return _theme2.default;
  }
});
Object.defineProperty(exports, "sproutLightTheme", {
  enumerable: true,
  get: function get() {
    return _theme.default;
  }
});
var _theme = _interopRequireDefault(require("./light/theme"));
var _theme2 = _interopRequireDefault(require("./dark/theme"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }