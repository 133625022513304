  const ELEVATION_LEVEL_100 = '0px 2px 4px';
  const ELEVATION_LEVEL_300 = '0px 8px 16px';
  const ELEVATION_LEVEL_400 = '0px 16px 32px';

export {
  ELEVATION_LEVEL_100,
  ELEVATION_LEVEL_300,
  ELEVATION_LEVEL_400
};

export default {
  ELEVATION_LEVEL_100,
  ELEVATION_LEVEL_300,
  ELEVATION_LEVEL_400
};