  const BORDER_RADIUS_400 = '4px';
  const BORDER_RADIUS_500 = '6px';
  const BORDER_RADIUS_600 = '8px';
  const BORDER_RADIUS_900 = '24px';
  const BORDER_RADIUS_1000 = '999999px';
  const BORDER_WIDTH_500 = '1px';
  const BORDER_WIDTH_600 = '2px';

export {
  BORDER_RADIUS_400,
  BORDER_RADIUS_500,
  BORDER_RADIUS_600,
  BORDER_RADIUS_900,
  BORDER_RADIUS_1000,
  BORDER_WIDTH_500,
  BORDER_WIDTH_600
};

export default {
  BORDER_RADIUS_400,
  BORDER_RADIUS_500,
  BORDER_RADIUS_600,
  BORDER_RADIUS_900,
  BORDER_RADIUS_1000,
  BORDER_WIDTH_500,
  BORDER_WIDTH_600
};